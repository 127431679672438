/* -------------------------- Header Start ---------------------------------- */

.btn-color {
  background-color: #045050;
  color: #ffffff;
}

.btn-color:hover {
  color: #045050;
  border-color: #045050;
}

.nav-link {
  color: black;
}

.nav-link:hover {
  color: #eb7e23;
}

.nav-active {
  color: #eb7e23;
}

/* -------------------------- Header End ---------------------------------- */

/* -------------------------- Carousel Start ---------------------------------- */

.banner-crsl-main-div video {
  /* background-color: ; */
  width: 100%;
  /* height: 100vh; */
}

#characters::before {
  content: "";
  height: 70px;
  margin-top: -70px;
  display: block;
  visibility: hidden;
}

.title {
  margin-bottom: 20px;
  padding: 20px;
}
.img {
  height: 100%;
  width: 100%;
}
.card-fonts {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  /* line-height: 18px; */

  color: #ffffff;
}

.flip-card {
  background-color: transparent;
  width: 360px;
  height: 400px;
  perspective: 1000px;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip-card-front {
  background-color: #bbb;
  color: black;
}

.flip-card-back {
  background-color: #045050;
  color: white;
  transform: rotateY(180deg);
  display: grid;
  align-items: center;
}

/* -------------------------- Carousel End ---------------------------------- */

/* -------------------------- Gallery Start --------------------------------- */
#gallery::before {
  content: "";
  height: 70px;
  margin-top: -70px;
  display: block;
  visibility: hidden;
}
.gallery p {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 35px;
  line-height: 68px;
  display: flex;
  align-items: center;

  color: #045050;
}

/* -------------------------- Gallery End --------------------------------- */

/* -------------------------- Torture Watch Start --------------------------------- */

#torture-watch::before {
  content: "";
  height: 70px;
  margin-top: -70px;
  display: block;
  visibility: hidden;
}

.today-in-na {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 27.3926px;
  line-height: 32px;

  color: #212529;
}

.row {
  margin: 0px;
}

.latest-news {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 49px;

  color: #045050;
}
body {
  overflow-x: hidden;
}

/* -------------------------- Torture Watch End --------------------------------- */

/* -------------------------- Footer Start --------------------------------- */

a {
  color: #ffffff;
  text-decoration: none;
}

a:hover {
  color: #045050;
  text-decoration: none;
}

.footer-main-div {
  width: 100%;
  height: auto;

  background: #333333;
}

.linkhov {
  color: #ffffff;
}

.linkhov:hover {
  color: #045050;
}

.footer-section {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.footer-icons {
  align-items: flex-start;
  font-size: 20px;
  display: flex;
  gap: 20px;
  color: white;
}
.footer-icons li {
  list-style: none;
}
.footer-section h3 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 35px;
  line-height: 35px;
  display: flex;
  align-items: center;

  color: #ffffff;
}
@media screen and (max-width: 567px) {
  .footer-section h3 {
    font-size: 20px;
  }
}
@media screen and (max-width: 567px) {
  .sm-d-none {
    display: none;
  }
}
.footer-section p {
  color: #ffffff;
}

/* -------------------------- Footer End --------------------------------- */

.slick-next:before,
.slick-prev:before {
  color: #045050 !important;
}
